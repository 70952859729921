
import {Component, Mixins, Watch, Prop, Vue, Emit} from "vue-property-decorator";
import {mapGetters, mapMutations} from "vuex";
import Page from "@/components/for-page-grid/Page.vue";
import XButton from "@/components/SimpleButton.vue";
import XSlider from "@/components/SimpleSlider.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import AppealAddingForm from "@/components/for-appeals/AppealAddingForm.vue";
import AppealsGrid from "@/components/for-appeals/AppealsGrid.vue";
import {AppealChatMessages, AppealsByContract, AppealThemes} from "@/models/appeals";
import {AppealsByContractAsReqBody,AppealThemesAsReqBody} from "@/models/app-api";

@Component({
  components: {
    Page,
    AppealAddingForm,
    AppealsGrid,
    XButton,
    XSlider
  },
  computed: {
    ...mapGetters({
      contractId: "contract/id"
    }),
    ...mapGetters({
      getErrorMessage: "error/currentSpec"
    }),
  },
  methods: {
    ...mapMutations("appeals", {
      setAppealsByContract: "setAppealsByContract",
    }),
    ...mapMutations("sidebar", {
      setCounterValue: "setCounterValue",
    }),
  }
})
class Appeals extends Mixins(AppApiMixin) {
  [x: string]: any;

  showAppealAddingForm = false;
  listAppeals: AppealsByContract[] = [];

  public appealsPropsChanged() {
    this.getAppealsByContract(
      {contractId: this.contractId},
      (listAppeals: AppealsByContract[]) => {
        let sorted_app = [];
        let tr1 = Array.from(listAppeals).filter(item => item['непрочитано'] > 0 && item['обращение-результат>признаки'] % 4 >=2);
        let tr2 = Array.from(listAppeals).filter(item => item['обращение-результат>название'] == '');
        let tr3 = Array.from(listAppeals).filter(item => item['обращение-результат>название'] != '');

        let set = new Set();

        tr1.forEach((item) => {
          set.add(item);
        });
        tr2.forEach((item) => {
          set.add(item);
        });
        tr3.forEach((item) => {
          set.add(item);
        });
        set.forEach(item => sorted_app.push(item));

        this.setUnreadCounterValue(tr1);
        this.listAppeals = sorted_app;
        this.setAppealsByContract(this.listAppeals);
      }
    );
  }
}

export default Appeals

